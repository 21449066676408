<template>
    <!-- https://www.npmjs.com/package/vue-flatpickr-component -->
    <label class="mb-0 text-primary fw-bold">{{ title_ }} <span v-if="required_" class="text-danger">*</span></label>
    <!-- {{ modelValue }} -->
    <div :class="'input-group ' + classGroup_ + ' ' + ' ' + (error_ ? 'is-invalid': '')">
        <flatPickr
            class="form-control rounded"
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            :config="flatpickrOptions"
            :placeholder="placeholder_"
            :disabled="disabled_"
        ></flatPickr>
    </div>
    <small v-if="error_" class="text-danger fst-italic error-text lh-sm">
        <span v-for="(error,index) in error_" :key="index" class="d-block">
            {{ error }}
        </span>
    </small>
</template>

<script>
import { Indonesian } from "flatpickr/dist/l10n/id.js";
// import moment from "moment-timezone";

export default {
    props: {
        classGroup_:                { type: String, default: 'input-group-outline' },   //input-group-dynamic
        class_:                     { type: String, default: '' },
        title_:                     { type: String },
        error_:                     { type: Array },
        placeholder_:               { type: String },
        modelValue:                 { type: [String, Date] },
        disabled_:                  { type: Boolean, default: false },
        required_:                  { type: Boolean, default: false },
        minDate_:                   { type: Date, default: null },
        maxDate_:                   { type: Date, default: null },
        clearable_:                 { type: Boolean, default: true },
    },
    emits: ['update:modelValue','value'],
    data() {
        return {
        flatpickrInstance: null,
        flatpickrOptions: {
                locale: Indonesian,
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d",
                enableTime: false,
                minDate: null,
                maxDate: null,
                static:true,
                appendTo: document.body,
                position: "auto", // Default auto position
                onReady: (selectedDates, dateStr, instance) => {
                    this.flatpickrInstance = instance;
                    instance.setDate(dateStr,true,'Y-m-d');
                    instance.set('minDate',this.minDate_);
                    instance.set('maxDate',this.maxDate_);
                },
            },
        };
    },
    // beforeMount(){
        // if(this.minDate != null) this.minDate = moment(this.minDate_).format('yyyy-MM-DD');
        // if(this.maxDate != null) this.maxDate = moment(this.maxDate_).format('yyyy-MM-DD');
    // }
}
</script>

<style>
    .is-invalid{
        border: solid #dc3545 1px !important;
        border-radius: 0.375rem !important;
    }
    .dp__input{
        font-weight: 400 !important;
        line-height: 1.62 !important; 
    }
    .dp__disabled{
        background-color: #dcdde2 !important;
    }
    .sunday {
        background-color:#ff7e72;
        color: black;
        border-radius: 0%;
        margin: 2px;
    }
</style>